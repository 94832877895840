<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <a class="btn btn-outline-secondary mr-sm-3" [routerLink]="['/NegocioConcluido']"><i class="mdi mdi-keyboard-backspace"></i> {{'btn.voltar'|translate}}</a>

      <h5>BOLETA - PRECIFICAÇÃO {{boleta?.componenteNome}} N° {{boleta?.negocioId}}</h5>
      <div class="row p-3">
        <div class="col-6">
          <strong>Data:</strong><span> {{boleta?.dataConclusao | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="col-6">
          <strong>Usuário:</strong><span> {{boleta?.usuarioNome}}</span>
        </div>
        <div class="col-6">
          <strong>Produto:</strong><span> {{boleta?.produtoNome}}</span>
        </div>
        <div class="col-6">
          <strong>Ordem:</strong><span> {{boleta?.produtoNome}}</span>
        </div>
        <div class="col-6">
          <strong>Porto:</strong><span> {{boleta?.nomePorto}}</span>
        </div>
        <div class="col-6">
          <strong>Boleta:</strong><span> {{boleta?.boleta}}</span>
        </div>
        <div class="col-6">
          <strong>Vendedor:</strong><span> {{boleta?.vendedor}}</span>
        </div>

        <div class="col-6">
          <strong>{{'precificacao.premio' | translate}}:</strong><span> {{boleta?.premio?.descricao}} {{boleta?.premio?.valor | number:'1.2-2'}}</span>
        </div>
        <div class="col-6">
          <strong>Contraparte:</strong><span> {{boleta?.contraparte}}</span>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="editarForm" (ngSubmit)="salvarDados()">

        <div class="form-row">
          <div class="form-group col-md-6 pl-3">
            <label for="premio">{{'precificacao.premio' | translate}}</label>
            <div class="input-group mb-3">
             
              <input type="text"
                     class="form-control"
                     placeholder="Valor"
                     aria-label="Valor prêmio"
                     formControlName="premioValor"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }"
                     aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="form-group col-md-3 pl-3">
            <label for="quantidade">Quantidade</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">{{boleta?.unidadeNegociada}}</span>
              </div>
              <input type="number"
                     class="form-control"
                     placeholder="Quantidade"
                     aria-label="Quantidade informada"
                     formControlName="quantidade"
                     aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="form-group col-md-3 pl-3">
            <label for="cbot">CBOT</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
                <span class="input-group-text">{{boleta?.cbotCode}}</span>
              </div>
              <input type="text" class="form-control"
                     formControlName="cbotValor"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }">
            </div>
          </div>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <td class="align-bottom" colspan="4">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="quantidade">Rodo</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon2">R$</span>
                      </div>
                      <input type="text" class="form-control"
                             placeholder="Frete rodoviário"
                             aria-label="Frete rodoviário"
                             formControlName="freteRodoValor"
                             aria-describedby="basic-addon2"
                             currencyMask
                             [options]="{  prefix: '', thousands: '.', decimal: ',',precision:2 }">
                    </div>
                  </div>

                  <div class="form-group col-sm-12 col-md-3">
                    <label for="freteRodoData">Data</label>
                    <div class="input-group">
                      <input class="form-control" aria-label="Data rodoviário"
                             [(ngModel)]="freteRodoData"
                             [ngModelOptions]="{standalone: true}"
                             #d="ngbDatepicker"
                             (click)="d.toggle()"
                             (ngModelChange)="obterQuintoDiaUtilRodo(freteRodoData)"
                             placeholder="dd/mm/yyyy" ngbDatepicker aria-describedby="basic-addon2">
                    </div>
                  </div>

                  <div class="form-group col-sm-12 col-md-3">
                    <label for="freteHedgeRodo">Hedge</label>
                    <div class="input-group">
                      <input type="text"
                             class="form-control"
                             placeholder="Data"
                             aria-label="Hedge rodoviário"
                             formControlName="freteHedgeRodo"
                             aria-describedby="basic-addon2"
                             currencyMask
                             [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision: 4 }">
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3 d-flex align-self-center align-items-end flex-column">
                    <span class="p-2 bd-highlight">{{freteEmReal(editarForm.controls.freteRodoValor.value)  | currency:'BRL'}}</span>
                    <span class="p-2 bd-highlight">{{freteEmDolar(editarForm.controls.freteRodoValor.value, editarForm.controls.freteHedgeRodo.value)  | currency}}</span>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td class="align-bottom" colspan="4">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="freteFerroValor">Ferro</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon2">R$</span>
                      </div>
                      <input type="text" class="form-control"
                             placeholder="Frete ferroviário"
                             aria-label="Frete ferroviário"
                             formControlName="freteFerroValor"
                             aria-describedby="basic-addon2"
                             currencyMask
                             [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }">
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="freteFerroData">Data</label>
                    <div class="input-group">
                      <input class="form-control" aria-label="Data ferroviário"
                             [(ngModel)]="freteFerroData"
                             [ngModelOptions]="{standalone: true}"
                             #dtFerro="ngbDatepicker"
                             (click)="dtFerro.toggle()"
                             (ngModelChange)="obterQuintoDiaUtilFerro(freteFerroData)"
                             placeholder="dd/mm/yyyy"
                             ngbDatepicker
                             aria-describedby="basic-addon2">
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="freteHedgeFerro">Hedge</label>
                    <div class="input-group">
                      <input type="text"
                             class="form-control"
                             placeholder="Data"
                             aria-label="Hedge ferroviário"
                             formControlName="freteHedgeFerro"
                             aria-describedby="basic-addon2"
                             currencyMask
                             [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision: 4 }">
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3 d-flex align-self-center align-items-end flex-column">
                    <span class="p-2 bd-highlight">{{freteEmReal(editarForm.controls.freteFerroValor.value)  | currency:'BRL'}}</span>
                    <span class="p-2 bd-highlight">{{freteEmDolar(editarForm.controls.freteFerroValor.value,  editarForm.controls.freteHedgeFerro.value)  | currency}}</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="align-bottom" colspan="4">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="quantidade">Produto</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon2">{{boleta?.produtoSiglaMoeda}}</span>
                      </div>
                      <input type="text"
                             class="form-control"
                             placeholder="Quantidade"
                             aria-label="Quantidade informada"
                             formControlName="produtoValor"
                             aria-describedby="basic-addon2"
                             currencyMask
                             [options]="{ prefix: '', thousands: '.', decimal: ',' }">
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-3">
                    <div [hidden]="boleta?.dataHedgeProduto == null">
                      <label for="dataHedgeProduto">Data</label>
                      <div class="input-group">
                        <input class="form-control" aria-label="Data produto"
                               [(ngModel)]="dataHedgeProduto"
                               [ngModelOptions]="{standalone: true}"
                               #dtMercado="ngbDatepicker"
                               (click)="dtMercado.toggle()"
                               (ngModelChange)="obterQuintoDiaUtilMercado(dataHedgeProduto)"
                               placeholder="dd/mm/yyyy"
                               ngbDatepicker aria-describedby="basic-addon2">
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-3">
                    <div [hidden]="boleta?.produtoTipoMoeda != MoedaEnum.REAL">
                      <label for="freteHedgeFerro">Hedge</label>
                      <div class="input-group">
                        <input type="text"
                               class="form-control"
                               placeholder="Data"
                               aria-label="Hedge mercado"
                               formControlName="hedgeProduto"
                               aria-describedby="basic-addon2"
                               currencyMask
                               [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision:4 }">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3 d-flex align-self-center align-items-end flex-column">
                    <span *ngIf="boleta?.produtoTipoMoeda == MoedaEnum.REAL" class="p-2 bd-highlight">
                      {{calcularvalorProdutoReal | currency:'BRL'}}
                    </span>
                    <span class="p-2 bd-highlight">{{calcularvalorProdutoDolar | currency}}</span>
                  </div>
                </div>
              </td>


            </tr>
            <tr class="bg-dark">
              <td class="text-light align-bottom text-lg-center" colspan="4">
                <strong>Componentes</strong>
              </td>
            </tr>
            <ng-container *ngFor="let item of componentes; let i = index;">
              <tr [formGroup]="item">
                <td class="align-bottom" colspan="4">
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-3">
                      <label for="valor">{{item.controls.nome.value}} ({{item.controls.unidadeNome.value}})</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">{{item.controls.moedaSigla.value}}</span>
                        </div>
                        <input type="text" class="form-control"
                               placeholder="Valor" aria-label="Informe o valor"
                               formControlName="valor"
                               aria-describedby="basic-addon2"
                               currencyMask
                               [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }">
                      </div>
                    </div>
                    <ng-container *ngIf="item.controls.possuiEdge.value">
                      <div class="form-group col-sm-12 col-md-3">
                        <label for="freteFerroData">Data</label>
                        <div class="input-group">
                          <input class="form-control"
                                 placeholder="Data"
                                 aria-label="Data componente"
                                 formControlName="dataHedge"
                                 #dt="ngbDatepicker" (click)="dt.toggle()"
                                 ngbDatepicker
                                 (ngModelChange)="obterQuintoDiaUtilComponente(item)"
                                 aria-describedby="basic-addon2">
                        </div>
                      </div>

                      <div class="form-group col-sm-12 col-md-3">
                        <label for="freteHedgeFerro">Hedge</label>
                        <div class="input-group">
                          <input type="text"
                                 class="form-control"
                                 placeholder="Valor"
                                 aria-label="Hedge"
                                 formControlName="valorHedge"
                                 aria-describedby="basic-addon2"
                                 currencyMask
                                 [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision: 4 }">
                        </div>
                      </div>
                    </ng-container>

                    <div class="col-sm-12 col-md-{{item.controls.possuiEdge.value ? '3' : '9'}} d-flex align-self-center align-items-end flex-column">
                      <span class="p-2 bd-highlight">
                        <ng-container *ngIf="!mostrarSeMoedaForDolar(item.controls.moedaId.value) && !porcentagem(item.controls.moedaId.value)">
                          {{calcularReal(item.controls.valor.value,item.controls.valorHedge.value, item.controls.moedaId.value)  | currency:'BRL'}}
                        </ng-container>
                      </span>
                      <span class="p-2 bd-highlight">
                        <ng-container>
                          {{calcularDolar(item.controls.valor.value, item.controls.valorHedge.value, item.controls.moedaId.value)  | currency}}
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <tr class="text-light bg-secondary table-bordered">
              <td class="align-bottom" colspan="4">
                <div class="row align-middle text-lg-right">
                  <div class="col-sm-12 col-md-3">
                    <strong>Parcial</strong>
                    <div class="row text-right">
                      <span class="col-12">{{totalSomaComponentesEmReais | currency: 'BRL'}}</span>
                      <span class="col-12">{{totalSomaComponentesEmDolares | currency}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <strong>Total em Reais</strong>
                    <div class="row text-right">
                      <span class="col-12">{{totalComponentesEmReais | currency: 'BRL'}}</span>
                      <span class="col-12"></span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <strong>Com/Sem Edge</strong>
                    <div class="row text-right">
                      <span class="col-12">{{totalComponentesEmDolares | currency}}</span>
                      <span class="col-12">{{totalComponentesSemEdgeEmDolares | currency}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <strong>Geral</strong>
                    <div class="row text-right">
                      <span class="col-12">{{totalComponentesEmDolares | currency}}</span>
                      <span class="col-12">{{totalComponentesSemEdgeEmDolares | currency}}</span>
                    </div>
                  </div>
                </div>

              </td>
             
            </tr>
            <tr class="bg-dark">
              <th class="text-light align-bottom text-lg-center" colspan="4">
                <strong>RESUME HEDGE EXPOSURE FX</strong>
              </th>
            </tr>
            <tr>
              <td>
                <div class="row">

                  <strong class="col-12">Logística</strong>
                  <sub class="col-12">Rodo+Ferro</sub>
                </div>
              </td>
              <td class="align-middle text-lg-right" colspan="3">
                <div class="row">
                  <span class="col">{{totalRodoMaisFerroEmDolar | currency}}</span>
                  <span class="col">{{totalRodoMaisFerroEmReal | currency: 'BRL'}} </span>

                </div>
              </td>
            </tr>
            <tr>
              <th>
                <strong>Produto</strong>
              </th>
              <td class="align-middle text-lg-right" colspan="3">
                <div class="row">
                  <span class="col">{{calcularvalorProdutoDolar | currency}}</span>
                  <span class="col">{{calcularvalorProdutoReal | currency: 'BRL'}}</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div class="row">
                  <strong class="col-12">Elevação</strong>
                  <sub class="col-12">*Soma que contém edge</sub>
                </div>
              </th>
              <td class="align-middle text-lg-right" colspan="3">
                <div class="row">
                  <span class="col">{{totalComponentesEmDolares | currency}}</span>
                  <span class="col">{{calcularElecacaoQuePossuiHedgeEmReal | currency: 'BRL'}}</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div class="row">
                  <strong class="col-12">Elevação</strong>
                  <sub class="col-12">*Soma somente dólar</sub>
                </div>
              </th>
              <td class="align-middle text-lg-right" colspan="3">
                <div class="row">
                  <span class="col">{{totalComponentesSemEdgeEmDolares | currency}}</span>
                  <span class="col"></span>
                </div>
              </td>
            </tr>
            <tr class="text-light bg-secondary">
              <td class="align-bottom">
                <strong>Total</strong>
              </td>
              <td class="align-middle text-lg-right" colspan="3">
                <div class="row">
                  <span class="col">{{totalResumeEmDolares | currency}}</span>
                  <span class="col">{{totalResumeEmReais | currency: 'BRL'}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>

        </div>
        <div class="pl-3">
          <button type="submit" class="btn btn-success mr-2">{{'btn.salvar'|translate}} <i class="mdi mdi-content-save"></i></button>
        </div>

      </form>
    </nb-card-body>
  </nb-card>
</main>
